import React from 'react';

import { Image } from 'nordic/image';
import { Button, ButtonText } from '@andes/button';
import { CarouselSnappedSlide } from '@andes/carousel-snapped';

import RenderLogo from '../render-logo';
import ItemBookmark from '../../../../bookmark/bookmark';
import { trackEvent } from '../../../../../lib/tracking';
import { namespaceMobile as namespace } from '../../constants';
import LabelComponent from './label-content';
import { slideBillboardMobPropType } from '../../proptypes';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} SlideBillboardItemProps
 * @property {string} logo_url - La URL del logo asociado al elemento.
 * @property {string} title - El título del elemento.
 * @property {string} image_url - La URL de la imagen del elemento.
 * @property {string} image_alt_text - El texto alternativo para la imagen.
 * @property {string} component_description - La descripción del componente.
 * @property {Object} action - Las acciones asociadas al elemento.
 * @property {string} action.target - La URL del enlace asociado a la acción.
 * @property {Object} action.tracks - Los datos de seguimiento asociados a la acción.
 */

/**
 * Componente que renderiza un elemento del billboard deslizante.
 *
 * @param {SlideBillboardItemProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el elemento del billboard deslizante.
 */
const SlideBillboard = ({ item, index }) => {
  const {
    logo_url,
    title,
    image_url,
    image_alt_text,
    component_description,
    action: {
      target,
      tracks,
      label: { text: labelText },
    },
  } = item;

  return (
    <CarouselSnappedSlide>
      <div className="billboard-intervention__slide--card-top">
        {logo_url && (
          <RenderLogo
            index={index}
            logo_url={logo_url}
            title={title}
            classNameContainer="billboard-intervention__logo-image-container"
            classNameImage="billboard-intervention__slide--card-top--logo"
            image_alt_text={image_alt_text}
          />
        )}
        <ItemBookmark className="ui-search-billboard__bookmark" item={item} />
      </div>
      <a
        href={target || '#'}
        className={`${namespace}__link-wrapper`}
        onClick={() => {
          trackEvent(tracks);
        }}
      >
        <Image
          src={image_url}
          alt={title || image_alt_text}
          className="billboard-intervention__slide--card-img"
          preload={index < 2}
          lazyload={index < 2 ? 'off' : 'on'}
        />
      </a>
      <div className={`${namespace}__content`}>
        {component_description.map((value) => LabelComponent({ ...value }))}
        <Button
          href={target}
          fullWidth
          size="medium"
          className={`${namespace}__action-button`}
          onClick={() => {
            trackEvent(tracks);
          }}
        >
          <ButtonText>{labelText}</ButtonText>
        </Button>
      </div>
    </CarouselSnappedSlide>
  );
};

SlideBillboard.propTypes = {
  ...slideBillboardMobPropType,
};

export default withComponentHandler(SlideBillboard, { componentName: 'SlideBillboard' });
