import React from 'react';
import { string, bool, number } from 'prop-types';

import { Image } from 'nordic/image';

import { namespace } from '../constants';
import { LAZY_OFF, LAZY_ON } from '../../../../constants';
import { withComponentHandler } from '../../../../hocs/with-component-handler';
/**
 * @typedef {Object} RenderLogoProps
 * @property {string} [image_alt_text] - El texto alternativo para la imagen del logo.
 * @property {number} [index] - El índice del logo en la lista.
 * @property {boolean} [isDesktop] - Indica si el logo se está renderizando en vista de escritorio.
 * @property {string} logo_url - La URL de la imagen del logo.
 * @property {boolean} [lowEnd] - Indica si se debe usar una versión de baja calidad.
 * @property {string} title - El título asociado al logo.
 * @property {string} [type] - El tipo de logo (opcional).
 */

/**
 * Componente que renderiza un logo con soporte para diferentes resoluciones y carga diferida.
 *
 * @param {RenderLogoProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el logo renderizado.
 */
const RenderLogo = ({ logo_url, lowEnd, title, isDesktop = false, type, index = 0, image_alt_text }) => (
  <figure
    className={
      isDesktop
        ? `${namespace}__logo-image-container ${namespace}__logo-image-container--${type}`
        : 'billboard-intervention__logo-image-container'
    }
  >
    <Image
      className={
        isDesktop
          ? `${namespace}__logo-image ${namespace}__logo-image--${type}`
          : 'billboard-intervention__slide--card-top--logo'
      }
      alt={title || image_alt_text}
      src={logo_url}
      preload={index === 0}
      lazyload={lowEnd ? LAZY_OFF : LAZY_ON}
    />
  </figure>
);

RenderLogo.propTypes = {
  image_alt_text: string,
  index: number,
  isDesktop: bool,
  logo_url: string.isRequired,
  lowEnd: bool,
  title: string.isRequired,
  type: string,
};

export default withComponentHandler(RenderLogo, { componentName: 'RenderLogo' });
