import React from 'react';
import { number, string } from 'prop-types';

import LabelBuilder from '../../../label-builder';
import { withComponentHandler } from '../../../../hocs/with-component-handler';
/**
 * @typedef {Object} RenderPricesProps
 * @property {number} amount - El monto asociado al precio.
 * @property {string} id - El identificador único del precio.
 * @property {string} size - El tamaño del texto del precio.
 * @property {string} currencyId - El identificador de la moneda.
 * @property {string} className - Clases CSS adicionales para el precio.
 * @property {string} thousandSeparator - El separador de miles para el precio.
 * @property {string} symbol - El símbolo de la moneda.
 */

/**
 * Componente que renderiza los precios utilizando `LabelBuilder`.
 *
 * @param {RenderPricesProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene los precios renderizados.
 */
const RenderPrices = ({ amount, id, size, currencyId, className, thousandSeparator, symbol }) => (
  <LabelBuilder
    values={[
      {
        description: amount,
        key: `${id}-price`,
        text: amount,
        type: 'PRICE',
        size,
        className,
        thousandSeparator,
        currencyId,
        symbol,
      },
    ]}
    text={`{${id}-price}`}
  />
);

RenderPrices.propTypes = {
  amount: number.isRequired,
  className: string.isRequired,
  currencyId: string.isRequired,
  id: string.isRequired,
  size: string.isRequired,
  symbol: string,
  thousandSeparator: string.isRequired,
};

export default withComponentHandler(RenderPrices, { componentName: 'RenderPrices' });
