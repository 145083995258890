import React from 'react';

import { Image } from 'nordic/image';
import { CarouselSnappedSlide, CarouselSnapped } from '@andes/carousel-snapped';

import VerticalCardContent from './vertical-card-content';
import ItemBookmark from '../../../../bookmark/bookmark';
import { namespace } from '../../constants';
import { slideBillboardWrapper } from '../../proptypes';
import { trackEvent } from '../../../../../lib/tracking';
import { LAZY_OFF, LAZY_ON } from '../../../../../constants';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} SlideBillboardItem
 * @property {string} id - El identificador único del elemento.
 * @property {Object} tracks - Los datos de seguimiento asociados al elemento.
 * @property {string} image - La URL de la imagen del elemento.
 * @property {string} title - El título del elemento.
 * @property {string} subtitle - El subtítulo del elemento.
 */

/**
 * @typedef {Object} SlideBillboardWrapperProps
 * @property {SlideBillboardItem[]} items - Los elementos que se mostrarán en el billboard.
 * @property {boolean} lowEnd - Indica si se debe usar una versión de baja calidad.
 * @property {string} type - El tipo de billboard.
 * @property {boolean} showUserBookmarks - Indica si se deben mostrar los marcadores del usuario.
 */

/**
 * Componente que renderiza un billboard deslizante con múltiples elementos.
 *
 * @param {SlideBillboardWrapperProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el billboard deslizante.
 */
const SlideBillboardWrapper = ({ items, lowEnd, type, showUserBookmarks }) => (
  <CarouselSnapped
    arrows={{ size: 'large' }}
    spacing={0}
    type="full"
    pagination={false}
    lazyload
    beforeChange={(index) => {
      const track = items[index]?.tracks;

      trackEvent(track);
    }}
  >
    {items.map((item, index) => {
      const { item_id, image_url, title } = item;

      return (
        <CarouselSnappedSlide
          className={`${namespace}__card billboard-carousel-snapped__slide`}
          key={`slide-bill-board-${item_id}`}
        >
          <VerticalCardContent item={item} type={type} lowEnd={lowEnd} index={index} />
          <Image
            className={`${namespace}__background`}
            src={image_url}
            preload={index === 0}
            lazyload={index === 0 ? LAZY_OFF : LAZY_ON}
            carousel
            alt={title}
          />
          {showUserBookmarks && <ItemBookmark className={`${namespace}__bookmark`} item={item} />}
        </CarouselSnappedSlide>
      );
    })}
  </CarouselSnapped>
);

SlideBillboardWrapper.propTypes = {
  ...slideBillboardWrapper,
};

export default withComponentHandler(SlideBillboardWrapper, { componentName: 'SlideBillboardWrapper' });
