import React from 'react';
import { string, shape } from 'prop-types';

import { actionPropTypes } from '../../proptypes';
import { trackEvent } from '../../../../../lib/tracking';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} HeaderInfoBillboardProps
 * @property {Object} action - Las acciones asociadas al encabezado.
 * @property {string} namespace - El espacio de nombres CSS para el componente.
 * @property {Object} strapline - El texto destacado asociado al encabezado.
 * @property {string} title - El título del encabezado.
 * @property {string} type - El tipo de encabezado.
 */

/**
 * Componente que renderiza el encabezado de un billboard con un título, strapline y acciones.
 *
 * @param {HeaderInfoBillboardProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el encabezado del billboard.
 */
const HeaderInfoBillboard = ({ action, namespace, strapline, type, title }) => (
  <div className={`${namespace}__header-info`}>
    {strapline && <span className={`${namespace}__strapline`}>{strapline?.text}</span>}
    <h2 className={`${namespace}__title ${namespace}__title--${type?.toLowerCase()}`}>
      <a
        href={action?.target || '#'}
        className={`${namespace}__link`}
        onClick={() => {
          trackEvent(action?.tracks || {});
        }}
      >
        {title}
      </a>
    </h2>
  </div>
);

HeaderInfoBillboard.propTypes = {
  action: { ...actionPropTypes },
  namespace: string,
  strapline: shape(),
  title: string,
  type: string,
};

export default withComponentHandler(HeaderInfoBillboard, { componentName: 'HeaderInfoBillboard' });
