import React from 'react';

import { Button, ButtonText } from '@andes/button';

import BillboardAttributes from '../../../../../components/billboard/attributes/attributes.desktop';
import HeaderLogo from './HeaderLogo';
import HeaderInfoBillboard from './HeaderInfoBillboard';
import RenderPrices from '../render-prices';
import { namespace } from '../../constants';
import { verticalContentRendererPropType } from '../../proptypes';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} VerticalCardContentItem
 * @property {string} title - El título del elemento.
 * @property {Object[]} specifications - Las especificaciones del elemento.
 * @property {Object} strapline - El texto destacado asociado al elemento.
 * @property {Object} from - Información adicional sobre el origen del elemento.
 * @property {Object} price - Información sobre el precio del elemento.
 * @property {Object} action - Las acciones asociadas al elemento.
 * @property {string} logo_url - La URL del logo asociado al elemento.
 */

/**
 * @typedef {Object} VerticalCardContentProps
 * @property {VerticalCardContentItem} item - Los datos del elemento que se renderizará.
 * @property {string} type - El tipo de tarjeta vertical.
 * @property {boolean} lowEnd - Indica si se debe usar una versión de baja calidad.
 * @property {number} index - El índice del elemento en la lista.
 */

/**
 * Componente que renderiza el contenido de una tarjeta vertical en un billboard.
 *
 * @param {VerticalCardContentProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el contenido de la tarjeta vertical.
 */
const VerticalCardContent = ({
  item: { title, specifications, strapline, from, price, action, logo_url },
  type,
  lowEnd,
  index,
}) => (
  <div className={`${namespace}__content`}>
    <div className={`${namespace}__header`}>
      {logo_url && (
        <HeaderLogo
          action={action}
          namespace={namespace}
          title={title}
          index={index}
          logo_url={logo_url}
          lowEnd={lowEnd}
          type={type}
        />
      )}
      <HeaderInfoBillboard action={action} namespace={namespace} strapline={strapline} title={title} type={type} />
    </div>
    {from && <span className={`${namespace}__from`}>{from.text}</span>}
    <RenderPrices
      amount={price.amount}
      id={price.id}
      size={price.size}
      currencySymbol={price.currency_symbol}
      className={`${namespace}__price ${namespace}__price--${type?.toLowerCase()}`}
      thousandSeparator={price.thousandSeparator}
      currencyId={price.currency_id}
      symbol={price.symbol}
    />
    {specifications && (
      <BillboardAttributes
        attributes={specifications?.map((attr) => ({ id: attr.id, label: attr.name, data: attr.value_name }))}
      />
    )}
    <Button hierarchy="loud" size="medium" className={`${namespace}__action-button`}>
      <ButtonText>{action?.label?.text}</ButtonText>
    </Button>
  </div>
);

VerticalCardContent.propTypes = {
  ...verticalContentRendererPropType,
};

export default withComponentHandler(VerticalCardContent, { componentName: 'VerticalCardContent' });
