import React from 'react';

import classnames from 'classnames';
import { CarouselSnapped } from '@andes/carousel-snapped';

import { trackEvent } from '../../../lib/tracking';
import SlideBillboard from './components/mobile/slide-bill-board';
import { billboardInterventionMobPropType } from './proptypes';
import { namespaceMobile as namespace } from './constants';
import { VERTICAL_TYPE } from '../../../constants';
import { withComponentHandler } from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} BillboardInterventionMobileProps
 * @property {Object[]} intervention - Los datos de la intervención del billboard.
 * @property {string} intervention[].vertical - El tipo de diseño vertical asociado a la intervención.
 */

/**
 * Componente que renderiza una intervención de tipo billboard para dispositivos móviles.
 *
 * @param {BillboardInterventionMobileProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención del billboard móvil.
 */
const BillboardIntervention = ({ intervention: { contents: items } }) => {
  let { vertical } = items[0];

  if (vertical === VERTICAL_TYPE.MOT) {
    vertical = classnames(`${namespace}--mot`);
  }

  return (
    <section className={namespace}>
      <CarouselSnapped
        srLabel=""
        className={vertical === VERTICAL_TYPE.RES ? '' : vertical}
        arrows={{ visibility: 'hover' }}
        type="content"
        pagination={{
          mode: 'light',
          position: 'bottom',
        }}
        spacing={12}
        strictBoundaries={false}
        afterChange={(index) => {
          trackEvent(items[index].tracks);
        }}
      >
        {items.map((item, i) => (
          <SlideBillboard item={item} index={i} key={`carousel-snapped-${item.item_id}`} />
        ))}
      </CarouselSnapped>
    </section>
  );
};

BillboardIntervention.propTypes = {
  intervention: billboardInterventionMobPropType,
};

export default withComponentHandler(BillboardIntervention, { componentName: 'BillboardInterventionMobile' });
