import React from 'react';
import { string, number } from 'prop-types';

import classnames from 'classnames';

import { namespaceMobile as namespace } from '../../constants';
import LabelBuilder from '../../../../label-builder';
import RenderPrices from '../render-prices';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} LabelComponentProps
 * @property {string} font_size - El tamaño de la fuente del texto.
 * @property {string} color - El color del texto.
 * @property {string} font_family - La familia tipográfica del texto.
 * @property {string} text - El contenido del texto.
 * @property {string} id - El identificador del componente.
 * @property {string} size - El tamaño del componente.
 * @property {number} amount - El monto asociado al precio.
 * @property {string} currency_id - El identificador de la moneda.
 * @property {string} thousandSeparator - El separador de miles para el precio.
 * @property {string} symbol - El símbolo de la moneda.
 */

/**
 * Componente que renderiza un contenido de etiqueta o un precio dependiendo del identificador.
 *
 * @param {LabelComponentProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el contenido de la etiqueta o el precio.
 */
const LabelComponent = ({
  font_size,
  color,
  font_family,
  text,
  id,
  size,
  amount,
  currency_id,
  thousandSeparator,
  symbol,
}) => {
  if (id === 'LABEL_COMPONENT') {
    return (
      <LabelBuilder
        text={text}
        class_name={classnames(
          `${namespace}__content-label--${font_size}`,
          `${namespace}__content-label--${color}`,
          `${namespace}__content-label--${font_family}`,
        )}
      />
    );
  }

  const className = classnames(
    'ui-search-price',
    `${namespace}__content-label--${size}`,
    `${namespace}__content-label--${color}`,
  );

  return (
    <RenderPrices
      amount={amount}
      id={id}
      size={size}
      className={className}
      thousandSeparator={thousandSeparator}
      currencyId={currency_id}
      symbol={symbol}
    />
  );
};

LabelComponent.propTypes = {
  amount: number,
  color: string,
  currency_id: string,
  font_family: string,
  font_size: string,
  id: string,
  size: string,
  symbol: string,
  text: string,
  thousandSeparator: string,
};

export default withComponentHandler(LabelComponent, { componentName: 'LabelComponent' });
