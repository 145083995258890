import React from 'react';
import { string, bool, number } from 'prop-types';

import RenderLogo from '../render-logo';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} HeaderLogoProps
 * @property {number} index - El índice del logo.
 * @property {string} logo_url - La URL del logo.
 * @property {boolean} lowEnd - Indica si se debe usar una versión de baja calidad del logo.
 * @property {string} title - El título asociado al logo.
 * @property {string} type - El tipo de logo.
 */

/**
 * Componente que renderiza un logo en el encabezado para la vista de escritorio.
 *
 * @param {HeaderLogoProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el logo del encabezado.
 */
const HeaderLogo = ({ title, index, logo_url, lowEnd, type }) => (
  <RenderLogo index={index} title={title} logo_url={logo_url} lowEnd={lowEnd} type={type.toLowerCase()} isDesktop />
);

HeaderLogo.propTypes = {
  index: number,
  logo_url: string,
  lowEnd: bool,
  title: string,
  type: string,
};

export default withComponentHandler(HeaderLogo, { componentName: 'HeaderLogo' });
