import React, { useEffect, useState, useMemo } from 'react';

import classnames from 'classnames';

import SlideBillboard from './components/desktop/slide-bill-board';
import { trackEvent } from '../../../lib/tracking';
import { useStaticProps } from '../../../components/context/static-props';
import { useSearch } from '../../../hooks/context';
import { billboardInterventionDeskPropType } from './proptypes';
import { withComponentHandler } from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} BillboardInterventionProps
 * @property {Object} intervention - Los datos de la intervención del billboard.
 * @property {string} type - El tipo de intervención del billboard.
 */

/**
 * Componente que renderiza una intervención de tipo billboard con soporte para colapsar y marcadores de usuario.
 *
 * @param {BillboardInterventionProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención del billboard.
 */
const BillboardIntervention = ({ intervention: { contents: items, type } }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { lowEnd } = useStaticProps();
  const { bookmark } = useSearch();
  const showUserBookmarks = bookmark?.show_user_bookmarks;

  const classContainerIntervention = classnames('ui-search-carousel--billboard', {
    'ui-search-carousel--billboard--lowend': lowEnd,
    'ui-search-carousel--collapsed': isCollapsed,
  });

  const classToggleCollapseButton = classnames('ui-search-carousel__btn-collapse', {
    'ui-search-carousel__btn-collapsed': isCollapsed,
  });

  useEffect(() => {
    if (items.length) {
      trackEvent(items[0]?.tracks);
    }
  });

  const sliderMemo = useMemo(
    () => <SlideBillboard items={items} lowEnd={lowEnd} type={type} showUserBookmarks={showUserBookmarks} />,
    [items, lowEnd, type, showUserBookmarks],
  );

  return (
    <section className={classContainerIntervention}>
      {sliderMemo}
      <button
        className={classToggleCollapseButton}
        type="button"
        aria-label="reduce carrousel"
        tabIndex="0"
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      />
    </section>
  );
};

BillboardIntervention.propTypes = {
  ...billboardInterventionDeskPropType,
};

export default withComponentHandler(BillboardIntervention, { componentName: 'BillboardInterventionDesktop' });
